import React from 'react';
import { Carousel } from '../../components/Carousel';
import { Code } from 'react-content-loader';
import { PopularProducts } from '../../components/PopularProducts';
import { useMainRoute } from './useMainRoute';
import { MainRouteStyled } from './styles';
import DOMPurify from 'dompurify';

const sanitizedData = (data: any) => ({
  __html: DOMPurify.sanitize(data, { FORBID_TAGS: ['script'] }),
});

export const MainRoute = () => {
  const {
    homePage,
    loading
  }: { loading: any; homePage: any; } = useMainRoute();

  if (loading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    return (
      <MainRouteStyled>
        {homePage && (
          <style>{homePage.Styles}</style>
        )}
        {
        homePage && (
          homePage.Elements.map( (item: any, index: number) => { 
            if (item.__typename == 'fieldcollection_HomePageElementSlider') { 
              return (<PopularProducts products={item.SliderProducts} title={item.Title}></PopularProducts>);
            }
            if (item.__typename == 'fieldcollection_HomePageElementCarousel') { 
              return (<div className="carousel-container">
                <Carousel CarouselItems={item.CarouselItems}></Carousel>
              </div>);
            }
            if (item.__typename == 'fieldcollection_HomePageElementRT') { 
              return (<div dangerouslySetInnerHTML={sanitizedData(item.Content)} className='top'>
            </div>);
            }
          })
          )
        }
        <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'lightgray',
              padding: '6px',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '25%',
                height: '1px',
                backgroundColor: 'grey',
              }}
            />
            <p style={{ color: '#252525' }}>
              {' '}
              This is the end. Search for more.
            </p>
            <div
              style={{ width: '25%', height: '1px', backgroundColor: 'grey' }}
            />
          </div>
      </MainRouteStyled>
    );
  }
};

// const CategoryItem = styled.div`
//   display: inline-block;
//   margin-right: 8px;
//   border-radius: 4px;
//   width: 140px;
//   height: 160px;
//   background-color: #fafafafa;
//   flex-wrap: wrap;
//   ::-webkit-scrollbar {
//     display: none;
//   }
// `;
