import React from 'react';

import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';

interface ICarouseItem {
  ButtonLabel: string;
  Content: string;
  Title: string;
  Image: {
    fullpath: string;
  };
}

export const Carousel = ({ CarouselItems = [] }: any) => {
  return (
    <CarouselStyled>
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={true}
        className="swiper"
      >
        {CarouselItems?.length &&
          CarouselItems?.map(
            (
              { ButtonLabel, Content, Image, Title }: ICarouseItem,
              key: number
            ) => (
              <SwiperSlide key={key}>
                <Slide>
                  <div className="slide">
                    <p>{Title}</p>
                    <p>{Content}</p>
                    <button>{ButtonLabel}</button>
                  </div>
                  <img
                    src={`${imgSrcByEnv()}${Image?.fullpath}`}
                    alt="carousel"
                  />
                </Slide>
              </SwiperSlide>
            )
          )}
      </Swiper>
    </CarouselStyled>
  );
};

const CarouselStyled = styled.div`
  .swiper {
    /* padding-bottom: 50px; */
    border-radius: 10px;
  }
`;

const Slide = styled.div`
  display: flex;
  height: 400px;
  background-color: #ffffff;
  border-radius: 10px;

  @media (max-width: 768px) {
    display: block;
  }

  & > img {
    width: 50%;
    object-fit: cover;
    border-top-right-radius: 10px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .slide {
    width: 50%;
    display: flex;
    padding: 0 30px;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 100%;
      padding: 20px;
    }

    & > p:nth-of-type(1) {
      margin-bottom: 16px;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
      @media (max-width: 768px) {
        min-height: 68px;
      }
    }

    & > p:nth-of-type(2) {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    & > button {
      /* display: inline; */
      padding: 9px 16px;
      background: ${({ theme }: any) => theme.productAddToCartBg};
      border-radius: 3px;
      border: none;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      margin-top: 32px;

      @media (min-width: 550px) {
        width: fit-content;
        max-width: 60%;
      }
    }
  }
`;
