import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { useFilters } from './useFilters';
import {
  FiltersStyled,
  Header,
  PopularFiltersStyled,
  Line,
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { contentSquarePageEvent } from '../../helpers';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../theme/theme';
import { BrandChips } from '../BrandChip';
import { PillFiltersComponent } from './PillFilters';
import { PillFiltersWithImageComponent } from './PillFiltersWithImage';

export const PopularFilters = ({
  products,
  categories,
  brands,
  productsBeforeFiltering,
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
  categories: any;
  brands: string[];
}) => {
  const {
    value: { productsSorting, searchFilters },
    dispatch,
  } = useAppContext();

  const {
    handleFiltersVisibility,
    getCustomizedFilterData,
    translation,
    PillFiltersWithImageDisplayStyle,
    FilterType
  } = useFilters({
    products,
    productsBeforeFiltering,
  });

  const navigate = useNavigate();
  const { storeId = '', termId, harvestId = '' } = useParams();

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const brandFilter = searchFilters.find(
    (filter: any) => filter.name === 'Brand'
  );

  const edges = data?.getEPogTermListing?.edges;

  let filters = edges.map((edge: any) => edge?.node?.PillFilters).flat();

  let filtersWithImage = edges.map((edge: any) => edge?.node?.PillFiltersWithImage).flat();

  const isChecked = (brand: string) => brandFilter?.brands[brand] || false;

  const handleBrandClick = (brand: string) => {
    contentSquarePageEvent('brandFilter_' + brand);
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: {
        name: 'Brand',
        brands: {
          ...brandFilter?.brands,
          [brand]: !(brandFilter?.brands[brand] || false),
        },
      },
    });
  };
  const [fadeOut, setFadeOut] = useState(false);

  const handleFiltersClick = () => {
    setFadeOut(true);
    setTimeout(() => {
      handleFiltersVisibility();
      setFadeOut(false);
    }, 200);
  };

  return (
    <ThemeProvider theme={theme}>
      <FiltersStyled fadeOut={fadeOut}>
        <PopularFiltersStyled isLessThanSixItems={categories?.length}>
          {categories?.map((category: any, index: number) =>
            category?.TermImage?.fullpath ? (
              <div
                className="filter"
                key={index}
                onClick={() =>
                  navigate(
                    `/${storeId}/${harvestId}/category/${
                      category.id
                    }__${category.key.replaceAll(' ', '_')}`
                  )
                }
              >
                <img
                  src={
                    category?.TermImage?.fullpath
                      ? `${imgSrcByEnv()}${category?.TermImage?.fullpath}`
                      : '/files/generic/img/square.svg'
                  }
                  alt="carousel"
                />
                <img src="/files/generic/img/square.svg" alt="" />
                <p> {category?.key}</p>
              </div>
            ) : (
              ''
            )
          )}
        </PopularFiltersStyled>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              marginLeft: '12px',
            }}
          >
            <Header onClick={handleFiltersClick}>{translation?.getLocalizationAmzWm?.Filters || 'Filters'}</Header> 
          </div>
          <Line />
          {(products.length || (!products?.length && searchFilters?.length))
            && (FilterType == "Plain" || FilterType == "All") ? (
            filters.length <= 1 ? (
              <BrandChips
                brands={brands}
                handleBrandClick={handleBrandClick}
                isChecked={isChecked}
              />
            ) : ((FilterType === 'All' || FilterType === 'Plain') &&
              <PillFiltersComponent filters={filters} getCustomizedFilterData={getCustomizedFilterData} handleFiltersVisibility={handleFiltersVisibility} />
            )
          ) : (
            ''
          )}

          {(products.length || (!products?.length && searchFilters?.length)) &&
            (FilterType == "Image" || FilterType == "All") &&
            (PillFiltersWithImageDisplayStyle === "WithoutImages" ?
              <PillFiltersComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} squareFilterStyling={true} handleFiltersVisibility={handleFiltersVisibility} /> :
              <PillFiltersWithImageComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} />
            )
          }
        </div>
      </FiltersStyled>
    </ThemeProvider>
  );
};
