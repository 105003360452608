import styled from '@emotion/styled';


export const MainRouteStyled = styled.div`
display:flex;
height: 100%;
margin-top:65px;
margin-bottom:70px;
row-gap: 10px;
flex-direction: column;
`;
// export const MainRouteStyled = styled.div<{
//   CarouselWidth?: string;
//   SecondLineElementWidth?: string;
// }>`
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   gap: 24px;
//   margin-bottom: 40px;

//   @media (max-width: 768px) {
//     display: block;
//     max-width: 100vw;
//     /* padding: 0 10px; */
//   }

//   /* .carousel-container {
//     grid-column: ${({ CarouselWidth }) =>
//     CarouselWidth === 'Full' ? '1 / span 6' : '1 / span 4'};
//     background: #ffffff;
//     box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
//     border-radius: 10px;
//     margin-top: 24px;
//     width: 100%;

//     @media (max-width: 1069px) {
//       grid-column: 1 / span 6;
//     }
//   } */

//   .popular-sections-container {
//     grid-column: ${({ CarouselWidth }) =>
//     CarouselWidth === 'Full' ? '1 / span 6' : '5 / span 2'};
//     width: 100%;
//     @media (max-width: 1069px) {
//       grid-column: 1 / span 6;
//     }

//     @media (max-width: 768px) {
//       /* margin-top: 24px; */
//     }
//   }
//   .similar {
//     grid-column: 1 / span 6;
//     width: 100%;
//     margin-top: 24px;
// }

//   .product-image-slider-container {
//     grid-column: ${({ SecondLineElementWidth }) =>
//     SecondLineElementWidth === 'Full' ? '1 / span 6' : '1 / span 3'};
//     width: 100%;
//   }
//   .product-exposition-container {
//     grid-column: ${({ SecondLineElementWidth }) =>
//     SecondLineElementWidth === 'Full' ? '1 / span 6' : '4 / span 3'};
//     width: 100%;
//     @media (max-width: 768px) {
//       margin-top: 24px;
//     }
//   }

//   .product-slider-container {
//     grid-column: 1 / span 6;
//     width: 100%;
//   }
// `;
